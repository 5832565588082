const Partners = () => {
  const partList = [
    "/images/part20.png",
    "/images/part15.png",
    "/images/part2.png",
    "/images/part18.png",
    "/images/part16.png",
    "/images/part4.png",
    "/images/part12.png",
    "/images/part6.png",
    "/images/part14.png",
    "/images/part8.svg",
    "/images/part10.png",
    "/images/part11.png",
    "/images/part1.png",
    "/images/part3.png",
    "/images/part5.png",
    "/images/part7.png",
    "/images/part9.png",
    "/images/part13.png",
    "/images/part17.svg",
    "/images/part19.png",
    "/images/part21.jpg",
    "/images/part22.png",
    "/images/part23.png",
    "/images/part24.jpg",
    "/images/part25.png",
    "/images/part26.png",
    "/images/part27.png",
    "/images/part28.png",
    "/images/part29.png",
    "/images/part30.png",
  ];
  return (
    <div
      id="partners"
      className="relative w-full h-full px-[calc((100%-var(--container))/2)] mx-auto py-14 lg:py-28 xl:pb-36"
    >
      <div
        id="title"
        className="relative w-full h-fit text-center mb-5 lg:mb-10"
      >
        <h2 className="leading-none gmedium">깔끔요정의&nbsp;협력업체</h2>
        <p className="text-lg xl:text-xl mt-2 xl:mt-3 text-primary">
          - PARTNERS -
        </p>
      </div>
      <div className=" relative w-full h-full grid grid-cols-3 grid-rows-10 lg:grid-cols-5 lg:grid-rows-6 gap-5">
        {partList.map((v, i) => (
          <div
            data-aos="fade-up"
            key={i}
            className="relative w-full h-full border px-3 py-4"
          >
            <img
              className="!relative w-auto max-w-20 h-8 lg:max-w-32 lg:h-14 mx-auto object-contain"
              alt="logo"
              src={v}
              width={500}
              height={500}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Partners;
