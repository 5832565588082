const About = () => {
  const aboutList = [
    {
      img: "/images/about1.png",
      txt1: "365일 24시간 언제나",
      txt2: "무료 상담가능!",
    },
    {
      img: "/images/about2.png",
      txt1: "보태거나 더하지 않는",
      txt2: "오차없는 견적",
    },
    {
      img: "/images/about3.png",
      txt1: "부산 경남 전지역",
      txt2: "출장비 0원!",
    },
    {
      img: "/images/about4.png",
      txt1: "두 번 보지 않도록",
      txt2: "완벽한 해결!",
    },
  ];
  return (
    <div
      id="about"
      className="relative w-full h-full px-[calc((100%-var(--container))/2)]  mx-auto py-14 lg:py-28 xl:pb-36"
    >
      <div id="title" className="relative w-full h-fit text-center mb-10">
        <div className="relative w-full h-fit flex justify-center items-end flex-wrap sm:flex-nowrap gap-2">
          <div className="relative w-36 md:w-44 xl:w-52 2xl:w-56 h-fit">
            <img
              className="!relative w-full h-auto object-contain"
              alt="logo"
              src="/images/title.png"
              width={430}
              height={104}
            />
          </div>
          <h2 className="leading-none gmedium lg:pb-1 2xl:pb-0">
            <span className="hidden sm:inline-block">의</span> 약속&nbsp;4가지
          </h2>
        </div>
        <p className="text-lg lg:text-xl xl:text-2xl mt-2 xl:mt-3">
          깔끔요정은&nbsp;고객님께
          아래와&nbsp;같은&nbsp;혜택을&nbsp;약속드립니다!
        </p>
      </div>
      <div className="relative w-full h-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 lg:gap-5 ">
        {aboutList.map((v, i) => (
          <div key={i} data-aos="fade-up" className="relative w-full h-full">
            <img
              className="!relative w-full h-44 md:h-52 lg:h-80 2xl:h-[430px] 3xl:h-[500px] object-cover hover:-translate-y-5 transition-transform duration-300 rounded-lg"
              alt="img"
              src={v.img}
              width={434}
              height={641}
            />
            <div className="relative w-full h-16 lg:h-20 2xl:h-24 mt-2 lg:mt-4 border-2 border-primary text-center grid items-center rounded-lg">
              <div className="leading-tight">
                <h5 className="font-light">{v.txt1}</h5>
                <h3 className="text-nowrap text-primary gmarket xl:text-3xl mt-1">
                  {v.txt2}
                </h3>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default About;
