import { useEffect, useState } from "react";
import About from "../../components/home/About";
import Banner from "../../components/home/Banner";
import Blog from "../../components/home/Blog";
import Contact from "../../components/home/Contact";
import Event from "../../components/home/Event";
import Partners from "../../components/home/Partners";
import QnA from "../../components/home/QnA";
import Service from "../../components/home/Service";
import axios from "axios";

const Home = () => {
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    axios.get("/api/blog").then((res) => setData(res.data.data));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="relative w-full h-full">
      <Banner />
      <Event />
      <QnA />
      <About />
      <Service />
      <Blog data={data} />
      <Contact />
      <Partners />
    </div>
  );
};

export default Home;
