import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const AdminLogin = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const login = async () => {
    await axios
      .post("/api/admin/login", {
        email: email,
        password: password,
      })
      .then((res) => {
        if (res.data.result === "0000") {
          console.log(res.data);
          sessionStorage.setItem("id", res.data.id);
          sessionStorage.setItem("email", res.data.email);
          sessionStorage.setItem("name", res.data.name);
          Swal.fire({
            title: "관리자로그인",
            text: `${res.data.name}님 환영합니다.`,
            icon: "success",
          }).then(() => (window.location.href = "/admin/dashboard"));
        } else {
          Swal.fire({
            title: res.data.message,
            icon: "error",
          }).then(() => (window.location.href = "/"));
        }
      })
      .catch((e) =>
        Swal.fire({
          title: e,
          icon: "error",
        }).then(() => (window.location.href = "/"))
      );
  };
  return (
    <div className=" relative w-full h-full min-h-screen px-[calc((100%-var(--container))/2)] bg-[var(--gray-color)] py-14 lg:py-28">
      <div className=" relative w-full h-full max-w-screen-sm mx-auto bg-white rounded-2xl py-20  px-2 lg:px-5">
        <div
          onClick={() => navigate("/")}
          className=" relative w-fit h-11 mx-auto cursor-pointer"
        >
          <img
            className=" relative w-auto h-full object-contain"
            src={process.env.PUBLIC_URL + "/images/logo.png"}
            alt="logo"
          />
        </div>
        <div className=" relative w-full h-fit grid grid-rows-2 gap-5 max-w-[450px] my-12 mx-auto ">
          <input
            type="text"
            name="id"
            id="id"
            placeholder="아이디"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            name="password"
            id="password"
            placeholder="비밀번호"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="relative w-full h-fit max-w-[450px] mx-auto">
          <button
            type="button"
            onClick={login}
            className=" relative w-full h-fit bg-primary py-3 text-white rounded-sm"
          >
            <p className="font-black">관리자 로그인</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
