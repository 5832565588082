const Quick = () => {
  return (
    <div className="hidden xl:block fixed w-fit h-fit right-[calc((100%-var(--container))/4)] top-[350px] -translate-y-28 z-40 cursor-pointer border border-b-0 rounded-full">
      <div className="relative w-28 h-28 p-4 bg-primary rounded-full shadow-md z-10">
        <img
          alt="icon"
          className="!relative w-full h-full object-contain"
          src={"/images/white_logo.png"}
          width={115}
          height={133}
        />
      </div>
      <div className="-mt-12 pt-16 pb-7 relative w-full h-fit bg-white grid justify-items-center text-main">
        <p
          onClick={() => {
            let menuHeight =
              document.querySelector<HTMLDivElement>("#header")!.offsetHeight;

            var location =
              document.querySelector<HTMLDivElement>("#contact")!.offsetTop;

            window.scrollY &&
              window.scrollTo({
                top: location - menuHeight,
                behavior: "smooth",
              });
          }}
          className="font-bold text-primary pb-2"
        >
          견적신청
        </p>
        <div className=" relative w-full h-1 px-3 opacity-50">
          <div className=" relative w-full h-1 border-b border-primary"></div>
        </div>
        <button
          type="button"
          onClick={() => window.open("https://open.kakao.com/o/svItfWFg")}
          className="grid justify-items-center pt-5 pb-2"
        >
          <img
            alt="icon"
            src={"/images/icon_kakao.png"}
            width={55}
            height={55}
          />
          <p className="font-bold text-primary mt-1">카톡상담</p>
        </button>
        <div className=" relative w-full h-1 px-3 opacity-50">
          <div className=" relative w-full h-1 border-b border-primary"></div>
        </div>
        <button
          type="button"
          onClick={() =>
            window.open("https://talk.naver.com/ct/w5vr2r?frm=pmd")
          }
          className="grid justify-items-center pt-5"
        >
          <img
            alt="icon"
            src={"/images/icon_talk.png"}
            width={55}
            height={55}
          />
          <p className="font-bold text-primary mt-1">톡톡상담</p>
        </button>
      </div>
      <div
        onClick={() => window.scrollTo({ top: 5, behavior: "smooth" })}
        className="-mt-6 pt-2 relative w-full h-fit bg-primary rounded-full text-white text-center shadow-md z-10"
      >
        <h3 className="leading-5">TOP</h3>
        <small>맨위로 이동</small>
      </div>
    </div>
  );
};

export default Quick;
