const MobileQuick = () => {
  return (
    <div className="fixed right-0 bottom-0 w-full h-fit z-40 lg:hidden">
      <div className="grid grid-cols-2 lg:block">
        <div
          onClick={() => (window.location.href = "tel:010-7299-0318")}
          className=" relative w-full h-full cursor-pointer border border-white border-b-0"
        >
          <img
            className="!relative w-full h-full object-cover"
            src="/images/quick5.png"
            alt="quick"
            width={260}
            height={95}
          />
        </div>
        <div
          onClick={() => {
            let menuHeight =
              document.querySelector<HTMLDivElement>("#header")!.offsetHeight;

            var location =
              document.querySelector<HTMLDivElement>("#contact")!.offsetTop;

            window.scrollY &&
              window.scrollTo({
                top: location - menuHeight,
                behavior: "smooth",
              });
          }}
          className=" relative w-full h-full cursor-pointer border border-white border-b-0"
        >
          <img
            className="!relative w-full h-full object-cover"
            src="/images/quick6.png"
            alt="quick"
            width={260}
            height={95}
          />
        </div>
      </div>
      <div className="grid grid-cols-3 lg:block">
        <div
          onClick={() => window.open("https://ggyj0318.modoo.at/")}
          className=" relative w-full h-full cursor-pointer border border-white"
        >
          <img
            className="!relative w-full h-full object-cover"
            src="/images/quick2.png"
            alt="quick"
            width={260}
            height={95}
          />
        </div>
        <div
          onClick={() => window.open("https://open.kakao.com/o/svItfWFg")}
          className=" relative w-full h-full cursor-pointer border border-white"
        >
          <img
            className="!relative w-full h-full object-cover"
            src="/images/quick3.png"
            alt="quick"
            width={260}
            height={95}
          />
        </div>
        <div
          onClick={() =>
            window.open("https://talk.naver.com/ct/w5vr2r?frm=pmd")
          }
          className=" relative w-full h-full cursor-pointer border border-white"
        >
          <img
            className="!relative w-full h-full object-cover"
            src="/images/quick4.png"
            alt="quick"
            width={260}
            height={95}
          />
        </div>
      </div>
    </div>
  );
};

export default MobileQuick;
