import axios from "axios";
import { useState } from "react";
import Swal from "sweetalert2";

const Contact = () => {
  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [service, setService] = useState<string>("");
  const [contents, setContents] = useState<string>("");

  const [loading, setLoading] = useState(false);

  const handlerPostContact = async () => {
    if (!name || !phone || !address) {
      Swal.fire({
        title: "이름, 연락처, 주소를 모두 입력해주세요.",
        icon: "error",
      });
      return false;
    }

    if (phone.length !== 11 || !phone.includes("010")) {
      alert("010을 포함한 연락처를 모두 입력해주세요.");
      return false;
    }

    if (!window.confirm("문의 접수를 위한 개인정보 수집에 동의하시나요?")) {
      return false;
    }

    setLoading(true);

    await axios
      .post("/api/contact", {
        name: name,
        phone: phone,
        address: address,
        service: service,
        contents: contents,
        path: sessionStorage.getItem("path"),
      })
      .then((res) => {
        if (res.data.result === "0000") {
          Swal.fire({
            title: "문의 완료!",
            text: "문의 등록이 완료되었습니다.",
            icon: "success",
          }).then(() => (window.location.href = "/transform"));
        }
      })
      .catch((e) => {
        setLoading(false);
        Swal.fire({
          title: e,
          icon: "error",
        }).then(() => (window.location.href = "/"));
      });
  };
  return (
    <div
      id="contact"
      className="relative w-full h-full px-[calc((100%-var(--container))/2)] bg-[#0E6CA5] mx-auto py-14 lg:py-28 xl:pb-36"
    >
      <div
        id="title"
        className="relative w-full h-fit text-center mb-5 lg:mb-10"
      >
        <div className="relative w-full h-fit flex justify-center items-end flex-wrap sm:flex-nowrap">
          <div className="relative w-36 md:w-44 xl:w-52 2xl:w-56 h-fit">
            <img
              className="!relative w-full h-auto object-contain"
              alt="logo"
              src="/images/title.png"
              width={430}
              height={104}
            />
          </div>
          <h2 className="leading-none text-white gmedium">&nbsp;견적문의</h2>
        </div>
      </div>
      <div className="relative w-full h-full z-10">
        <div className=" relative w-full h-full bg-white max-w-screen-lg rounded-3xl shadow-2xl py-10 px-5 xl:p-10 mx-auto">
          <div className=" relative w-full h-full grid lg:grid-cols-2 gap-5 xl:gap-8">
            <div className=" relative w-full h-full grid grid-cols-5 items-center">
              <label htmlFor="name" className="lg:text-xl">
                이름&nbsp;<span className="text-[var(--red-color)]">*</span>
              </label>
              <input
                className="col-span-4 bg-gray-200 !rounded-xl"
                type="text"
                value={name}
                id="name"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className=" relative w-full h-full grid grid-cols-5 items-center">
              <label htmlFor="phone" className="lg:text-xl">
                연락처&nbsp;<span className="text-[var(--red-color)]">*</span>
              </label>
              <input
                className="col-span-4 bg-gray-200 !rounded-xl"
                type="text"
                value={phone}
                id="phone"
                onChange={(e) =>
                  setPhone(
                    e.target.value
                      .replace(/[^0-9.]/g, "")
                      .replace(/(\..*)\./g, "$1")
                  )
                }
              />
            </div>
            <div className="lg:col-span-2 relative w-full h-full grid grid-cols-5 lg:grid-cols-10 items-center">
              <label htmlFor="address" className="lg:text-xl">
                주소&nbsp;<span className="text-[var(--red-color)]">*</span>
              </label>
              <input
                className="col-span-4 lg:col-span-9 bg-gray-200 !rounded-xl"
                type="text"
                id="address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div className=" relative w-full h-full grid grid-cols-5 items-center">
              <label htmlFor="service1" className="lg:text-xl">
                문의종류
              </label>
              <select
                className="col-span-4 bg-point text-primary"
                name="service1"
                id="service1"
                onChange={(e) => setService(e.target.value)}
              >
                <option value="">원하시는 서비스를 선택해주세요</option>
                <option value="유품정리">유품정리</option>
                <option value="쓰레기집">쓰레기집</option>
                <option value="빈집정리">빈집정리</option>
                <option value="고독사현장">고독사현장</option>
                <option value="특수청소">특수청소</option>
                <option value="폐기물처리">폐기물처리</option>
                <option value="가전가구수거">가전가구수거</option>
                <option value="사업장폐기물">사업장폐기물</option>
              </select>
            </div>
            <div className="hidden lg:block"></div>
            <div className="lg:col-span-2 relative w-full h-full grid grid-cols-5 lg:grid-cols-10 items-start">
              <label htmlFor="contents" className="lg:text-xl">
                문의 내용
              </label>
              <div className="col-span-4 lg:col-span-9">
                <textarea
                  className="bg-gray-200 !rounded-xl"
                  value={contents}
                  name="contents"
                  id="contents"
                  onChange={(e) => setContents(e.target.value)}
                ></textarea>
                <div className=" hidden lg:flex justify-between items-center relative w-full h-it">
                  <p className="leading-tight">
                    문자&nbsp;접수&nbsp;후&nbsp;
                    <span className="text-[var(--red-color)]">
                      20분&nbsp;이내에
                    </span>
                    <br />
                    직접&nbsp;전화드릴&nbsp;예정입니다.
                  </p>
                  <button
                    type="button"
                    onClick={handlerPostContact}
                    disabled={loading}
                    className=" relative w-fit h-fit py-2 px-5 text-point rounded-2xl bg-gradient-to-t to-[#01ffee] from-[#56bdff]"
                  >
                    <h3 className="manru text-shadow">상담신청</h3>
                  </button>
                </div>
              </div>
            </div>
            <div className=" flex lg:hidden justify-between items-center relative w-full h-it">
              <p className="leading-tight">
                문자&nbsp;접수&nbsp;후&nbsp;
                <span className="text-[var(--red-color)]">
                  20분&nbsp;이내에
                </span>
                <br />
                직접&nbsp;전화드릴&nbsp;예정입니다.
              </p>
              <button
                type="button"
                onClick={handlerPostContact}
                disabled={loading}
                className=" relative w-fit h-fit py-2 px-5 text-point rounded-2xl bg-gradient-to-t to-[#01ffee] from-[#56bdff]"
              >
                <h3 className="manru text-shadow">상담신청</h3>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className=" absolute left-[calc((100%-var(--container))/2)] bottom-0 z-0">
        <div className=" relative w-fit h-fit">
          <img
            className="!relative w-auto lg:h-96 object-contain"
            src="/images/contact-icon.png"
            alt="icon"
            width={1544}
            height={775}
          />
        </div>
      </div>
    </div>
  );
};

export default Contact;
