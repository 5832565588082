const QnA = () => {
  return (
    <div className="relative w-full h-full px-[calc((100%-var(--container))/2)] py-14 lg:py-28">
      <div id="title" className=" relative w-full h-full text-center mb-10">
        <h1 className="font-medium leading-tight">고객님들께서</h1>
        <h1 className="gmarket leading-tight text-primary">
          자주하시는&nbsp;질문!
        </h1>
        <p className="text-lg lg:text-xl xl:text-2xl mt-2 xl:mt-3">
          2~30대로&nbsp;구성된&nbsp;믿음직한&nbsp;청년일꾼!
          깔끔요정이&nbsp;도와드리겠습니다.
        </p>
      </div>
      <div className=" relative w-full h-full max-w-screen-xl mx-auto grid lg:grid-rows-4 gap-8 2xl:gap-10">
        <div
          data-aos="fade-up"
          className=" relative w-full h-full lg:flex justify-start items-center gap-5"
        >
          <div className="relative w-20 h-20 lg:w-24 lg:h-24 mx-auto lg:mx-0 p-2 lg:p-4 rounded-full border border-primary grid items-center">
            <img
              className="!relative w-full h-auto object-contain"
              src="/images/qna1.png"
              alt="icon"
              width={157}
              height={102}
            />
          </div>
          <div className=" relative w-full h-full mt-3 lg:mt-0">
            <h3 className="relative text-center lg:text-left lg:pl-5 z-10">
              Q.진행과정은 어떻게 될까요?
            </h3>
            <div className=" relative w-full h-fit bg-[#fffedc] p-5 rounded-2xl lg:rounded-full -mt-4">
              <p className=" lg:text-base xl:text-lg 2xl:text-xl">
                고객님께서&nbsp;버리실&nbsp;것들을&nbsp;빠짐없이{" "}
                <span className="text-[var(--red-color)] font-bold">
                  사진&nbsp;찍어&nbsp;보내주시면
                </span>{" "}
                보내주신&nbsp;사진을&nbsp;바탕으로{" "}
                <span className="text-[var(--red-color)] font-bold">
                  차량&nbsp;배차,&nbsp;작업&nbsp;인원,&nbsp;일정&nbsp;등을 조율
                </span>
                하고 견적&nbsp;상담이&nbsp;가능합니다.
              </p>
            </div>
          </div>
        </div>
        <div
          data-aos="fade-up"
          className=" relative w-full h-full lg:flex justify-start items-center gap-5"
        >
          <div className="relative w-20 h-20 lg:w-24 lg:h-24 mx-auto lg:mx-0 p-2 lg:p-4 rounded-full border border-primary grid items-center">
            <img
              className="!relative w-full h-auto object-contain"
              src="/images/qna2.png"
              alt="icon"
              width={122}
              height={122}
            />
          </div>
          <div className=" relative w-full h-full mt-3 lg:mt-0">
            <h3 className="relative text-center lg:text-left lg:pl-5 z-10">
              Q.유의사항이 있을까요?
            </h3>
            <div className=" relative w-full h-fit bg-[#fffedc] p-5 rounded-2xl lg:rounded-full -mt-4">
              <p className=" lg:text-base xl:text-lg 2xl:text-xl">
                폐기물&nbsp;안에&nbsp;귀중품이나&nbsp;버리면&nbsp;안되는&nbsp;물건이&nbsp;있으시면{" "}
                <span className="text-[var(--red-color)] font-bold">
                  미리&nbsp;챙겨주시거나&nbsp;말씀&nbsp;부탁드립니다.
                  처리장에&nbsp;도착하고&nbsp;나면&nbsp;물건을&nbsp;찾기&nbsp;어렵습니다.
                </span>
              </p>
            </div>
          </div>
        </div>
        <div
          data-aos="fade-up"
          className=" relative w-full h-full lg:flex justify-start items-center gap-5"
        >
          <div className="relative w-20 h-20 lg:w-24 lg:h-24 mx-auto lg:mx-0 p-2 lg:p-4 rounded-full border border-primary grid items-center">
            <img
              className="!relative w-full h-auto object-contain"
              src="/images/qna3.png"
              alt="icon"
              width={135}
              height={115}
            />
          </div>
          <div className=" relative w-full h-full mt-3 lg:mt-0">
            <h3 className="relative text-center lg:text-left lg:pl-5 z-10">
              Q.예약금이 있나요?
            </h3>
            <div className=" relative w-full h-fit bg-[#fffedc] p-5 rounded-2xl">
              <p className=" lg:text-base xl:text-lg 2xl:text-xl">
                당일&nbsp;취소,&nbsp;노쇼&nbsp;등의&nbsp;문제로&nbsp;업무에&nbsp;지장이&nbsp;많아{" "}
                <span className="text-[var(--red-color)] font-bold">
                  예약금&nbsp;입금&nbsp;확인&nbsp;후&nbsp;예약&nbsp;진행해&nbsp;드립니다.
                </span>
              </p>
            </div>
          </div>
        </div>
        <div
          data-aos="fade-up"
          className=" relative w-full h-full lg:flex justify-start items-center gap-5"
        >
          <div className="relative w-20 h-20 lg:w-24 lg:h-24 mx-auto lg:mx-0 p-2 lg:p-4 rounded-full border border-primary grid items-center">
            <img
              className="!relative w-full h-auto object-contain"
              src="/images/qna4.png"
              alt="icon"
              width={129}
              height={129}
            />
          </div>
          <div className=" relative w-full h-full mt-3 lg:mt-0">
            <h3 className="relative text-center lg:text-left lg:pl-5 z-10">
              Q.작업시간이 얼마나 소요되나요?
            </h3>
            <div className=" relative w-full h-fit bg-[#fffedc] p-5 rounded-2xl lg:rounded-full -mt-4">
              <p className=" lg:text-base xl:text-lg 2xl:text-xl">
                1톤차&nbsp;기준&nbsp;작업동선에&nbsp;따라
                1~2시간정도&nbsp;소요됩니다.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QnA;
