const Service = () => {
  const serviceList = [
    {
      img: "/images/service1.png",
      txt: "유품정리 전문",
      //   link: "https://blog.naver.com/PostList.naver?blogId=langlangshop&from=postList&categoryNo=32",
    },
    {
      img: "/images/service2.png",
      txt: "쓰레기집 정리",
      //   link: "https://blog.naver.com/PostList.naver?blogId=langlangshop&from=postList&categoryNo=33",
    },
    {
      img: "/images/service3.png",
      txt: "빈집정리 전문",
      //   link: "https://blog.naver.com/PostList.naver?blogId=langlangshop&from=postList&categoryNo=34",
    },
    {
      img: "/images/service4.png",
      txt: "고독사 정리",
      //   link: "https://blog.naver.com/PostList.naver?blogId=langlangshop&from=postList&categoryNo=35",
    },
    {
      img: "/images/service5.png",
      txt: "특수청소 전문",
      //   link: "https://blog.naver.com/PostList.naver?blogId=langlangshop&from=postList&categoryNo=36",
    },
    {
      img: "/images/service6.png",
      txt: "폐기물처리",
      //   link: "https://blog.naver.com/PostList.naver?blogId=langlangshop&from=postList&categoryNo=37",
    },
    {
      img: "/images/service7.png",
      txt: "가전 가구수거",
      //   link: "https://blog.naver.com/PostList.naver?blogId=langlangshop&from=postList&categoryNo=38",
    },
    {
      img: "/images/service8.png",
      txt: "사업장 폐기물",
      //   link: "https://blog.naver.com/PostList.naver?blogId=langlangshop&from=postList&categoryNo=39",
    },
  ];
  return (
    <div
      id="service"
      className="relative w-full h-full px-[calc((100%-var(--container))/2)] mx-auto py-14 lg:pb-28 xl:pb-48"
    >
      <div id="title" className="relative w-full h-fit text-center mb-10">
        <div className="relative w-full h-fit flex justify-center items-end flex-wrap sm:flex-nowrap">
          <div className="relative w-36 md:w-44 xl:w-52 2xl:w-56 h-fit">
            <img
              className="!relative w-full h-auto object-contain"
              alt="logo"
              src="/images/title.png"
              width={430}
              height={104}
            />
          </div>
          <h2 className="leading-none gmedium">&nbsp;서비스 목록</h2>
        </div>
        <p className="text-lg lg:text-xl xl:text-2xl mt-2 xl:mt-3">
          깔끔요정에서는&nbsp;아래와&nbsp;같은
          다양한&nbsp;서비스를&nbsp;제공해드립니다.
        </p>
      </div>
      <div className="relative w-full h-fit grid grid-cols-2 lg:grid-cols-4 gap-3 lg:gap-5">
        {serviceList.map((v, i) => (
          <div
            key={i}
            data-aos="fade-up"
            // onClick={() => window.open(v.link)}
            className="relative w-full h-full bg-white p-3 border shadow-lg rounded-md cursor-pointer"
          >
            <div className="relative w-full h-full overflow-hidden">
              <img
                className="!relative w-full h-52 md:h-80 lg:h-60 xl:h-80 3xl:h-[550px] object-cover hover:scale-110 transition-transform duration-300"
                alt="service"
                src={v.img}
                width={328}
                height={355}
              />
            </div>
            <div className="absolute bottom-0 left-0 w-full h-fit bg-white text-center text-light pt-3 pb-2">
              <p className="lg:text-xl xl:text-2xl text-nowrap gmedium">
                {v.txt}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Service;
