const Event = () => {
  const hashtag1 = ["유품정리", "쓰레기집청소", "특수청소", "빈집정리"];
  const hashtag2 = ["이민정리", "고독사청소", "폐기물처리", "이사정리"];
  return (
    <div className="relative w-full h-full bg-secondary pb-14 lg:pb-28 xl:pb-36 text-center">
      <div
        id="event-section1"
        className="relative w-full h-full py-14 lg:pt-20 3xl:pt-24 min-h-[630px] sm:min-h-[500px] lg:min-h-[680px] xl:min-h-[740px] 2xl:min-h-[800px] 3xl:min-h-[850px]"
      >
        <div className=" relative w-full h-full px-[calc((100%-var(--container))/2)]">
          <div className="md:flex justify-center items-start relative w-full h-fit">
            <h2 className="text-secondary">
              부산 · 경남 지역에서
              <span className="hidden md:block">&nbsp;&nbsp;</span>
            </h2>
            <div
              data-aos="fade-up"
              className="lg:-mx-1 relative w-fit h-14 lg:h-12 xl:h-14 2xl:h-16 mx-auto pt-1"
            >
              <img
                className="!relative w-auto h-full  object-contain"
                src="/images/event-title.png"
                alt="title"
                width={559}
                height={101}
              />
            </div>
          </div>
          <h1 className="gmarket text-point text-5xl lg:text-6xl 2xl:text-7xl mt-6 sm:mt-0">
            깔끔요정만 기억하세요!
          </h1>
          <div className=" relative w-full h-full max-w-screen-xl mx-auto mt-5 lg:mt-10">
            <div className=" relative w-full h-full text-secondary flex justify-center items-center flex-wrap md:flex-nowrap gap-2">
              {hashtag1.map((v, i) => (
                <button
                  key={v}
                  data-aos="fade-up"
                  className=" relative w-fit h-full bg-white rounded-full py-1 px-3 border border-secondary shadow-md drop-shadow-md hover:-translate-y-2 transition-transform duration-300"
                >
                  <h4 className="lg:text-2xl 2xl:text-3xl">
                    <span className=" italic">#&nbsp;</span>
                    {v}
                  </h4>
                </button>
              ))}
            </div>
            <div className=" relative w-full h-full text-secondary flex justify-center items-center flex-wrap md:flex-nowrap gap-2 mt-3 mb-5 2xl:mb-10">
              {hashtag2.map((v, i) => (
                <button
                  key={i}
                  data-aos="fade-up"
                  className=" relative w-fit h-full bg-white rounded-full py-1 px-3 border border-secondary shadow-md drop-shadow-md hover:-translate-y-2 transition-transform duration-300"
                >
                  <h4 className="lg:text-2xl 2xl:text-3xl">
                    <span className=" italic">#&nbsp;</span>
                    {v}
                  </h4>
                </button>
              ))}
            </div>
            <h2 className="text-point">
              고객님의 고민<span className="text-secondary">을</span> 완벽하게
              <span className="text-secondary">해결해드립니다.</span>
            </h2>
          </div>
        </div>
      </div>
      <div
        id="event-section2"
        className="relative w-full h-full px-[calc((100%-var(--container))/2)] mt-5 lg:mt-10"
      >
        <div className="md:flex justify-center items-start relative w-full h-fit">
          <h2 className="text-white">
            유품정리&nbsp;특수청소&nbsp;문의 &nbsp;
            <span className="hidden md:block">&nbsp;&nbsp;</span>
          </h2>
          <div
            data-aos="fade-up"
            className="lg:-mx-1 2xl:-mx-5 relative w-fit h-14 lg:h-12 xl:h-14 2xl:h-16 mx-auto pt-1"
          >
            <img
              className="!relative w-auto h-full  object-contain"
              src="/images/event-title2.png"
              alt="title"
              width={559}
              height={87}
            />
          </div>
        </div>
        <h1 className="gmarket text-[#a6e5ff] text-5xl lg:text-6xl 2xl:text-7xl mt-3 sm:mt-0">
          처음이고 막막합니다
        </h1>
        <div
          data-aos="fade-up"
          className=" relative w-full h-full max-w-screen-lg mx-auto mt-10 lg:mt-14"
        >
          <img
            className="hidden md:block relative w-full h-full object-contain"
            src="/images/event-bla.png"
            alt="icon"
            width={1619}
            height={901}
          />
          <img
            className="block md:hidden relative w-full h-full object-contain"
            src="/images/event-bla_mo.png"
            alt="icon"
            width={1202}
            height={999}
          />
        </div>
        <div className=" relative w-full h-fit max-w-screen-xl grid md:flex justify-center mx-auto gap-5 mt-10 lg:mt-14 text-secondary">
          <button
            type="button"
            onClick={() => window.open("https://blog.naver.com/ggyj0318")}
            data-aos="fade-right"
            className=" relative w-fit h-full py-3 md:py-5 px-14 rounded-lg md:rounded-full bg-gradient-to-t to-[#01ffee] from-primary hover:bg-gradient-to-b"
          >
            <h2 className="gmarket">
              실제&nbsp;
              <span className="text-point lg:text-secondary">
                작업사례
              </span>{" "}
              보러가기
            </h2>
            <h4 className="hidden lg:block text-point font-light text-right">
              ▶ 클릭하여 보러가기
            </h4>
          </button>
          <button
            type="button"
            onClick={() => {
              let menuHeight =
                document.querySelector<HTMLDivElement>("#header")!.offsetHeight;

              var location =
                document.querySelector<HTMLDivElement>("#contact")!.offsetTop;

              window.scrollY &&
                window.scrollTo({
                  top: location - menuHeight,
                  behavior: "smooth",
                });
            }}
            data-aos="fade-left"
            className=" relative w-fit h-full py-3 md:py-5 px-14 rounded-lg md:rounded-full bg-gradient-to-t to-[#DCF1FF] from-primary hover:bg-gradient-to-b"
          >
            <h2 className="gmarket">
              <span className="text-point lg:text-secondary">상담접수</span>
              &nbsp;신청 바로가기
            </h2>
            <h4 className="hidden lg:block text-point font-light text-right">
              ▶ 클릭하여 신청하기
            </h4>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Event;
