import axios from "axios";

import { dateFormat } from "../../../const/date";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Pagination } from "@mantine/core";

export type Inquiry = {
  id: number;
  name: string;
  phone: string;
  address: string;
  service: string;
  contents: string;
  ip: string;
  path: string;
  device: string;
  completeYn: string;
  created: Date;
};

const AContact = () => {
  const navigate = useNavigate();

  const [data, setData] = useState<Inquiry[] | null>(null);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    total < 1 &&
      axios.get(`/api/contact/count`).then((res) => setTotal(res.data.data));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    axios
      .get(`/api/contact?page=${page - 1}&size=10`)
      .then((res) => {
        if (res.data.result === "0000") {
          setData(res.data.data.content);
        }
      })
      .catch((e) => console.error(e));
  }, [page]);

  return (
    <div className=" relative w-full h-full">
      <div className="flex justify-between items-center">
        <h4>온라인문의</h4>
      </div>
      <div className=" relative w-full h-full my-10 border-t">
        <div className=" relative w-full h-full grid grid-cols-11 text-center border-b py-3 px-2">
          <p>번호</p>
          <p>이름</p>
          <p className="col-span-2">번호</p>
          <p className="col-span-2">주소</p>
          <p className="col-span-3">내용</p>
          <p className="col-span-2">작성일</p>
        </div>
        {data &&
          data.map((v, i) => (
            <div
              key={i}
              onClick={() => navigate(`/admin/inquiry/${v.id}`)}
              className=" relative w-full h-full grid grid-cols-11 text-center border-b py-3 px-2 cursor-pointer hover:bg-[var(--gray-color)]"
            >
              <p>{v.id}</p>
              <p className="ellipsis">{v.name}</p>
              <p className="col-span-2 ellipsis">{v.phone}</p>
              <p className="col-span-2 ellipsis txt_line">{v.address}</p>
              <p className="col-span-3 ellipsis txt_line">{v.contents}</p>
              <p className="col-span-2 ellipsis">{dateFormat(v.created)}</p>
            </div>
          ))}

        <div className=" relative w-fit h-fit mx-auto mt-10">
          {total > 0 && (
            <Pagination
              total={Math.ceil(total / 10)}
              value={page}
              onChange={setPage}
              color="gray"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AContact;
