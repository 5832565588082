import { dateFormat } from "../../const/date";

const Blog = ({ data }: { data: any[] }) => {
  const blog = ParseData({ data });

  return (
    <div
      id="blog"
      className="relative w-full h-full pt-14 lg:pt-28 bg-[#DCF1FF] px-[calc((100%-var(--container))/2)]"
    >
      <div
        id="title"
        className="relative w-full h-fit lg:text-left mb-10 lg:flex items-end gap-5 text-center"
      >
        <div className="relative w-full lg:w-fit h-fit flex justify-center lg:justify-start items-end flex-wrap sm:flex-nowrap gap-2">
          <div className="relative w-48 md:w-44 xl:w-52 2xl:w-56 h-fit">
            <img
              className="!relative w-full h-auto object-contain"
              alt="logo"
              src="/images/title.png"
              width={430}
              height={104}
            />
          </div>
          <h2 className="leading-none gmedium">BLOG&nbsp;작업사례</h2>
        </div>
        <p className="text-lg xl:text-xl 2xl:text-2xl">
          최신 작업사례를 실시간으로 확인해보세요!
        </p>
      </div>
      <div className=" relative w-full h-full min-h-[500px] flex flex-wrap-reverse xl:grid xl:grid-cols-3 mt-3 xl:mt-14 z-10">
        <div
          data-aos="fade-up"
          className="relative w-full h-full px-12 xl:px-0"
        >
          <img
            className="!relative w-auto h-full max-h-[380px] xl:max-h-[700px]"
            src="/images/phone.png"
            alt="phone"
            width={770}
            height={1040}
          />
        </div>
        <div className="relative w-full h-fit xl:col-span-2 grid grid-cols-2 xl:grid-cols-4 gap-2 2xl:gap-3 lg:px-10 mb-10 xl:mb-0">
          {blog &&
            blog.map((v: any, i: number) => (
              <div
                key={i}
                onClick={() => window.open(v.link[0])}
                className="relative w-full h-full"
              >
                <div className="relative w-full h-full grid bg-white p-2 md:p-3 rounded-3xl text-left border hover:border-[var(--main-color)] cursor-pointer transition-all duration-300 overflow-hidden">
                  <p
                    className={`relative w-full whitespace-nowrap overflow-hidden overflow-ellipsis lg:text-base h-fit mb-1`}
                  >
                    {v.title[0]}
                  </p>
                  <p
                    className={`text_line h-8 break-words line-clamp-2 text-xs opacity-60`}
                  >
                    {v.description[0]}
                  </p>
                  <div className="relative w-full pt-1 md:pt-2 flex justify-between items-end">
                    <small className="opacity-40 lg:text-sm txt_line">
                      {dateFormat(v.pubDate[0])}
                    </small>
                    <small className="lg:text-sm txt_line text-right text-primary">
                      {v.category[0]}
                    </small>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className=" absolute right-[calc((100%-var(--container))/2)] bottom-0 z-0 ">
        <img
          className="lg:max-w-screen-md 2xl:max-w-screen-lg 3xl:max-w-screen-xl"
          src="/images/blog-bg.png"
          alt="background"
          width={2116}
          height={553}
        />
      </div>
    </div>
  );
};

const parseString = require("xml2js").parseString;

const ParseData = ({ data }: { data: any[] }) => {
  const dataArr: {
    title: any;
    link: any;
    description: any;
    pubDate: any;
    category: any;
  }[] = [];

  data.length > 0 &&
    parseString(data, (e: any, data: any) => {
      // eslint-disable-next-line
      data.rss.channel[0].item.map((v: any, i: any) => {
        i < 8 &&
          dataArr.push({
            title: v.title,
            link: v.link,
            description: v.description,
            pubDate: v.pubDate,
            category: v.category,
          });
      });
    });

  return dataArr;
};

export default Blog;
