import axios from "axios";
import { useState } from "react";
import Swal from "sweetalert2";

const QuickBar = () => {
  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [contents, setContents] = useState("");

  const [loading, setLoading] = useState(false);

  const handlerPostContact = async () => {
    if (!name || !phone || !address) {
      Swal.fire({
        title: "이름, 연락처, 주소를 모두 입력해주세요.",
        icon: "error",
      });
      return false;
    }

    if (phone.length !== 11 || !phone.includes("010")) {
      alert("010을 포함한 연락처를 모두 입력해주세요.");
      return false;
    }

    if (!window.confirm("문의 접수를 위한 개인정보 수집에 동의하시나요?")) {
      return false;
    }

    setLoading(true);

    await axios
      .post("/api/contact", {
        name: name,
        phone: phone,
        address: address,
        service: "빠른문의",
        contents: contents,
        path: sessionStorage.getItem("path"),
      })
      .then((res) => {
        if (res.data.result === "0000") {
          Swal.fire({
            title: "문의 완료!",
            text: "문의 등록이 완료되었습니다.",
            icon: "success",
          }).then(() => (window.location.href = "/transform"));
        }
      })
      .catch((e) => {
        setLoading(false);
        Swal.fire({
          title: e,
          icon: "error",
        }).then(() => (window.location.href = "/"));
      });
  };

  return (
    <div className="hidden xl:block fixed left-0 bottom-0 w-full h-fit bg-primary px-[calc((100%-var(--container))/5)] z-40">
      <div className=" relative w-full h-full flex justify-center items-center gap-5 py-2">
        <div className="relative w-fit h-full py-1">
          <img
            className="!relative w-auto h-full max-w-[400px]"
            src="/images/quick_num.png"
            alt="number"
            width={584}
            height={107}
          />
          <div className="flex justify-center items-center gap-1 text-white pl-2">
            <input
              checked={true}
              readOnly
              type="checkbox"
              name="privacy"
              id="privacy"
            />
            <small>개인정보처리방침 동의 (필수)</small>
          </div>
        </div>
        <div className=" relative w-fit h-full min-w-[600px] grid grid-cols-7 grid-rows-2 gap-2">
          <div className="col-span-2 relative w-full h-full">
            <label
              htmlFor="name1"
              className=" absolute left-1.5 z-10 top-2 lg:text-xl text- font-semibold"
            >
              이름
            </label>
            <input
              type="text"
              value={name}
              className="!pl-12 !text-xs !rounded-xl"
              id="name1"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="col-span-2 relative w-full h-full">
            <label
              htmlFor="phone1"
              className=" absolute left-1.5 z-10 top-2 lg:text-xl text- font-semibold"
            >
              연락처
            </label>
            <input
              className="!pl-16 !text-xs !rounded-xl"
              type="text"
              id="phone1"
              value={phone}
              maxLength={11}
              onChange={(e) =>
                setPhone(
                  e.target.value
                    .replace(/[^0-9.]/g, "")
                    .replace(/(\..*)\./g, "$1")
                )
              }
            />
          </div>
          <div className="col-span-3 relative w-full h-full">
            <label
              htmlFor="address1"
              className=" absolute left-1.5 z-10 top-2 lg:text-xl text- font-semibold"
            >
              주소&nbsp;
            </label>
            <div>
              <input
                className="!pl-12 !text-xs !rounded-xl"
                type="text"
                value={address}
                id="address1"
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
          </div>
          <div className="col-span-7 relative w-full h-full">
            <label
              htmlFor="contents1"
              className=" absolute left-1.5 z-10 top-2 lg:text-xl text- font-semibold"
            >
              문의내용&nbsp;:&nbsp;
            </label>
            <div>
              <input
                className="!pl-24 !text-xs !rounded-xl"
                placeholder="문의내용을 간단하게 입력해주세요"
                type="text"
                value={contents}
                id="contents1"
                onChange={(e) => setContents(e.target.value)}
              />
            </div>
          </div>
        </div>
        <button
          type="button"
          disabled={loading}
          onClick={handlerPostContact}
          className="relative w-fit h-full"
        >
          <img
            className="!relative w-auto h-full max-w-56"
            src="/images/quick_btn.png"
            alt="button"
            width={349}
            height={131}
          />
        </button>
        <button
          type="button"
          onClick={() => window.open("https://blog.naver.com/ggyj0318")}
          className=" relative w-full h-full max-w-20"
        >
          <img
            className="!relative w-full max-w-20 h-full object-contain"
            src="/images/icon_blog.png"
            alt="icon"
            width={136}
            height={115}
          />
        </button>
      </div>
    </div>
  );
};

export default QuickBar;
