import { useEffect } from "react";

import { Route, Routes } from "react-router-dom";

import AOS from "aos";
import "aos/dist/aos.css";

import "@mantine/core/styles.css";
import Home from "./page/home";
import Layout from "./components/common/Layout";

import "./App.css";

import "./index.css";
import Transform from "./page/transform";
import AdminLogin from "./page/login";
import Dashboard from "./page/admin";
import AContact from "./page/admin/contact";
import AOneContact from "./page/admin/contact/detail";

function App() {
  const vh_height = () => {
    let vh = window.outerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  useEffect(() => {
    vh_height();

    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/transform" element={<Transform />} />
          <Route path="admin">
            <Route path="login" element={<AdminLogin />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="inquiry" element={<AContact />} />
            <Route path="inquiry/:id" element={<AOneContact />} />
          </Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
