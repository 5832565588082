import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const AOneContact = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState<{
    id: number;
    name: string;
    phone: string;
    address: string;
    service: string;
    contents: string;
    ip: string;
    path: string;
    device: string;
    completeYn: string;
    created: Date;
  }>();

  useEffect(() => {
    params.id &&
      axios.get(`/api/contact/${params.id}`).then((res) => {
        if (res.data.result === "0000") {
          setData(res.data.data);
          console.log(res.data.data);
        }
      });
  }, [params.id]);
  return (
    <div className=" relative w-full h-full">
      <div className="flex justify-between items-center">
        <h4>온라인문의</h4>
      </div>
      {data && (
        <div className=" relative w-full h-full my-10 border p-5 lg:p-10 grid grid-cols-2 gap-10 shadow-md">
          <div className=" relative w-full h-full grid grid-cols-2 gap-2 border-r">
            <p>고객명</p>
            <p>{data.name}</p>
          </div>
          <div className=" relative w-full h-full grid grid-cols-2 gap-2">
            <p>전화번호</p>
            <p>{data.phone}</p>
          </div>
          <div className="col-span-2 relative w-full h-full grid grid-cols-4 gap-2 ">
            <p>주소</p>
            <p>{data.address}</p>
          </div>
          <div className=" relative w-full h-full grid grid-cols-2 gap-2 border-r">
            <p>서비스</p>
            <p>{data.service}</p>
          </div>
          <div className="col-span-2 relative w-full h-full grid grid-cols-4 gap-2 ">
            <p>문의내용</p>
            <p className="col-span-3 !overflow-visible !whitespace-normal !break-normal">
              {data.contents}
            </p>
          </div>
        </div>
      )}

      <div className="relative w-full h-fit max-w-96 mx-auto my-7 lg:my-14">
        <button
          onClick={() => navigate(-1)}
          type="button"
          className=" relative w-full h-fit bg-[var(--gray-color)] py-3 rounded-sm"
        >
          <p className="font-black">목록으로</p>
        </button>
      </div>
    </div>
  );
};

export default AOneContact;
