import { Swiper, SwiperSlide } from "swiper/react";

import { Autoplay } from "swiper/modules";

import "swiper/css";

const Banner = () => {
  return (
    <div>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide className="bg-[#dcf1ff]">
          <img
            className="hidden md:block !relative w-full h-full min-h-[650px] object-cover object-center"
            src="/images/banner1.png"
            alt="banner1"
            width={2558}
            height={967}
          />
          <img
            className="block md:hidden !relative w-full h-full object-cover object-center"
            src="/images/banner1_mo.png"
            alt="banner1"
            width={770}
            height={1273}
          />
        </SwiperSlide>
        <SwiperSlide className="bg-[#fffedc]">
          <img
            className="hidden md:block !relative w-full h-full min-h-[650px] object-cover object-center"
            src="/images/banner2.png"
            alt="banner1"
            width={2558}
            height={967}
          />
          <img
            className="block md:hidden !relative w-full h-full object-cover object-center"
            src="/images/banner2_mo.png"
            alt="banner1"
            width={770}
            height={1273}
          />
        </SwiperSlide>
        <SwiperSlide className="bg-[#e2ffdc]">
          <img
            className="hidden md:block !relative w-full h-full min-h-[650px] object-cover object-center"
            src="/images/banner3.png"
            alt="banner1"
            width={2558}
            height={967}
          />
          <img
            className="block md:hidden !relative w-full h-full object-cover object-center"
            src="/images/banner3_mo.png"
            alt="banner1"
            width={770}
            height={1273}
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Banner;
